.m-nav {
  background: #fff;
  ul {
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    display: flex;
  }
  li {
    flex: 1;
    border-top: solid 3px transparent;
    border-bottom: solid 3px transparent;
    &:hover {
      border-top-color: #d62121 !important;
      background: #e4e4e4;
      a {
        color: #d62121;
      }
    }
  }
  a {
    display: block;
    text-decoration: none;
    line-height: 54px;
    text-align: center;
    font-size: 18px;
    color: #333;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 1000px) {
  .m-nav {
    a {
      line-height: 40px;
      font-size: 12px;
    }
    li {
      border-top: solid 2px transparent;
      border-bottom: solid 2px transparent;
    }
  }
}
