.banner {
  height: 500px;
  padding: 0 20px;
  background: url("../images/contact1.png") center center / cover no-repeat;
  &--slogan {
    padding-top: 210px;
    h2 {
      font-size: 50px;
      color: #ffffff;
      margin: 0;
    }
    p {
      font-size: 30px;
      color: #ffffff;
      margin: 0;
    }
  }
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 32px;
  line-height: 1;
  color: #111;
  text-align: center;
  padding: 50px 0;
  position: relative;
  font-weight: bold;
  &:after {
    position: absolute;
    content: "";
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 3px;
    background: #d62121;
  }
}
.light {
  background: url(../images/contact2.png) center center / cover no-repeat;
  &--list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 40px 0 160px 0;
    dt {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    dd {
      width: 440px;
      text-align: right;
      margin: 30px 0;
      &:nth-child(2n) {
        text-align: left;
      }
    }
    h2 {
      color: #c38a55;
      font-size: 24px;
      margin: 0 0 10px 0;
    }
    p {
      font-size: 16px;
      margin: 0;
    }
  }
}
.fly {
  height: 565px;
  background: url(../images/contact4.png) center center no-repeat;
  color: #fff;
  &--title {
    font-size: 32px;
    line-height: 1;
    text-align: center;
    padding: 70px 0 50px 0;
    font-weight: bold;
  }
  &--list {
    list-style: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 60px;
    margin: 0 0 0 40%;
    padding: 0;
    li {
      &:nth-child(2) {
        padding-left: 60px;
      }
      &:nth-child(3) {
        padding-left: 120px;
      }
      &:nth-child(4) {
        padding-left: 180px;
      }
      &:nth-child(5) {
        padding-left: 240px;
      }
    }
  }
}
.culture {
  background: url(../images/contact5.png) center center / cover no-repeat;
  padding-bottom: 80px;
  &--content {
    height: 530px;
    background: url(../images/contact6.png) center center / cover no-repeat;
  }
}
.position {
  background: url("../images/bg1.png") center center/ cover no-repeat;
  padding: 0 20px 50px 20px;
  overflow: hidden;
  &--item {
    position: relative;
    margin-bottom: 10px;
    &-more {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 30px 30px 10px 30px;
      color: #c09974;
      cursor: pointer;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        #ffffff 30%
      );
    }
    &-active {
      .position--item-content {
        height: auto;
      }
      .position--item-more {
        display: none;
      }
    }
    &-content {
      padding: 30px;
      margin-right: 50px;
      background: #fff;
      color: #333;
      overflow: hidden;
      height: 110px;
    }

    &-mail {
      width: 70px;
      height: 80px;
      padding-left: 15px;
      position: absolute;
      top: 30px;
      right: 0;
      display: flex;
      align-items: center;
      background: #c09974 url("../images/tdjl_right.png") 60px center no-repeat;
      font-size: 18px;
      text-decoration: none;
      color: #fff;
    }
    h2 {
      font-size: 24px;
      line-height: 1.5;
      margin: 0;
    }
    h3 {
      font-size: 16px;
      margin-bottom: 0;
    }
    ul {
      font-size: 14px;
      list-style-type: decimal;
      margin: 0;
      padding: 0 0 0 20px;
    }
  }
}
.contact {
  background: #f0f0f0 url("../images/contact10.png") center center / cover
    no-repeat;
  &--img {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    img {
      display: block;
    }
  }
  &--imgleft {
  }
  &--imgright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    li {
      width: 320px;
      text-align: center;
    }
    h2 {
      font-size: 24px;
      color: #333333;
      margin: 10px 0;
    }
  }
}
@media only screen and (max-width: 1000px) {
  .title {
    font-size: 30px;
    padding: 30px 0;
    &:after {
      bottom: 10px;
    }
  }
  .banner {
    height: 300px;
    &--slogan {
      padding-top: 150px;
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .light {
    &--list {
      display: block;
      padding: 20px;
      dt {
        display: none;
      }
      dd {
        width: auto;
        text-align: left;
      }
    }
  }
  .fly {
    height: auto;
    background: #bc946e;
    &--title {
      font-size: 24px;
      padding: 40px 0 0 0;
    }
    &--list {
      font-size: 16px;
      line-height: 1.5;
      margin: 0;
      padding: 20px;
      li {
        padding: 0 !important;
        margin: 20px 0;
      }
    }
  }
  .culture {
    padding-bottom: 30px;
    &--content {
      height: 200px;
      background-size: 100% auto;
    }
  }

  .contact {
    background: #f0f0f0;
    .title {
      color: #333;
    }
    &--img {
      display: block;
      img {
        width: 100%;
      }
    }
    &--list {
      display: block;
      padding: 20px 0;
      img {
        width: 50px;
      }
      li {
        width: auto;
        text-align: center;
      }
      h2 {
        font-size: 14px;
      }
    }
  }
}
