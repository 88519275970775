.banner {
  height: 500px;
  padding: 0 20px;
  background: url("../images/news1.png") center center / cover no-repeat;
  &--slogan {
    padding-top: 210px;
    h2 {
      font-size: 50px;
      color: #ffffff;
      margin: 0;
    }
    p {
      font-size: 30px;
      color: #ffffff;
      margin: 0;
    }
  }
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 32px;
  line-height: 1;
  color: #111;
  text-align: center;
  padding: 50px 0;
  position: relative;
  font-weight: bold;
  &:after {
    position: absolute;
    content: "";
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 3px;
    background: #d62121;
  }
}
.more {
  display: block;
  margin: 0 auto;
  width: 164px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: url("../images/xwdt_aniu.png") center center / cover no-repeat;
  font-size: 22px;
  color: #d62121;
  text-decoration: none;
}
.news {
  background: #f0f0f0 url("../images/bg1.png") center center/ cover no-repeat;
  padding: 0 20px;
  padding-bottom: 40px;
  &--nav {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-item {
      position: relative;
      display: inline-block;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      line-height: 54px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      font-size: 16px;
      color: #8c8c8c;
      cursor: pointer;
      opacity: 1;
      &:active,
      &.swiper-pagination-bullet-active {
        outline: none;
        background: #cda784;
        color: #fff;
        border-color: #cda784;
        &:before {
          display: block;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 50%;
        height: 6px;
        width: 1px;
        background: #cbcaca;
      }
      &:before {
        display: none;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -24px;
        margin-left: -3px;
        width: 6px;
        height: 6px;
        background: url("../images/jt2.png") center center / cover no-repeat;
      }
    }
  }
  &--line {
    height: 1px;
    background: #cbcaca;
    margin: 10px -20px 40px -20px;
  }
  &--list {
    &-item {
      position: relative;
      display: block;
      padding: 20px 30px;
      background: #f8f8f8;
      color: #333333;
      text-decoration: none;
      margin-bottom: 10px;
      &:hover {
        h2 {
          color: #d62121;
          text-decoration: underline;
        }
      }
      h2 {
        font-size: 18px;
        padding: 0 0 10px 0;
        margin: 0;
      }
      p {
        font-size: 14px;
        margin: 0;
      }
    }
    .more {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  &--company,
  &--media {
    line-height: 24px;
    padding: 0 6px;
    border-radius: 2px;
    font-size: 14px;
    color: #fff;
    float: left;
    margin-right: 10px;
  }
  &--company {
    background-image: linear-gradient(
      -54deg,
      rgba(155, 155, 155, 0.92) 0%,
      #494949 65%
    );
  }
  &--media {
    background-image: linear-gradient(
      -32deg,
      rgba(255, 221, 188, 0.92) 0%,
      #aa8058 87%
    );
  }
}
.interview {
  background: url(../images/news2.png) right top no-repeat,
    url(../images/news3.png) left bottom no-repeat;
  &--wrap {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: space-between;
  }
  &--item {
    overflow: hidden;
    background: #f8f8f8;
    padding: 15px 20px;
    width: 550px;
    margin-bottom: 20px;
    a {
      color: #333;
      text-decoration: none;
      &:hover h2 {
        color: #dd1618;
        text-decoration: underline;
      }
    }
  }
  &--head {
    width: 140px;
    height: 140px;
    border: solid 4px #aa7f57;
    overflow: hidden;
    float: left;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  &--content {
    overflow: hidden;
    white-space: normal;
    h2 {
      margin: 0;
      font-size: 18px;
      line-height: 26px;
    }
    h3 {
      font-size: 14px;
      color: #333;
      margin: 10px 0;
      font-weight: normal;
    }
    p {
      font-size: 12px;
      color: #8a8a8a;
      margin: 0;
      padding-left: 16px;
      background: url(../images/news5.png) left center no-repeat;
    }
  }
  .more {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.responsibility {
  background: url(../images/news6.png) center center / cover no-repeat;
  &--wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
  }
  &--item {
    display: flex;
    align-items: center;
    background: #1f1f1f;
    height: 430px;
    overflow: hidden;
  }
  &--img {
    width: 250px;
    position: relative;
    img {
      display: block;
    }
    h2 {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 24px;
      color: #fff;
    }
  }
  &--content {
    width: 0;
    overflow: hidden;
    color: #fff;
    padding: 0;
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(-500%);
    h2 {
      font-size: 28px;
      text-align: center;
      position: relative;
      padding: 10px 0;
      margin-bottom: 40px;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 44px;
        height: 3px;
        margin-left: -22px;
        background: #d62121;
      }
    }
    p {
      font-size: 16px;
      line-height: 30px;
    }
  }
  &--active {
    .responsibility--content {
      width: 350px;
      padding: 0 30px;
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .title {
    font-size: 30px;
    padding: 30px 0;
    &:after {
      bottom: 10px;
    }
  }
  .banner {
    height: 300px;
    &--slogan {
      padding-top: 150px;
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .more {
    width: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }
  .news {
    padding-bottom: 20px;
  }
  .interview {
    &--item {
      width: 100%;
    }
    &--head {
      float: none;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    &--content {
      margin-top: 20px;
    }
  }
  .responsibility {
    &--wrap {
      display: block;
    }
    &--item {
      display: block;
      height: auto;
    }
    &--img {
      width: 100%;
      height: 100vw;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
      h2 {
        display: none;
      }
    }
    &--content {
      width: auto !important;
      padding: 0 20px !important;
      opacity: 1;
      transform: translateY(0);
      h2 {
        font-size: 28px;
        text-align: center;
        position: relative;
        padding: 10px 0;
        margin-bottom: 40px;
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 44px;
          height: 3px;
          margin-left: -22px;
          background: #d62121;
        }
      }
      p {
        font-size: 16px;
        line-height: 30px;
      }
    }
    &--active {
      .responsibility--content {
        width: 350px;
        padding: 0 30px;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
