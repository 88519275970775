.m-head {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 78px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.4);
  z-index: 999;
  &--active {
    background: #1d426a;
  }
  .login {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    border-radius: 3px;
    width: 140px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    float: right;
    margin: 17px;
    font-size: 20px;
    color: #ffffff;
    text-decoration: none;
  }
  .logo {
    width: 230px;
    height: 78px;
    background: url(../images/logo.svg) center center / 80% auto no-repeat;
  }
  .menu {
    float: right;
    display: flex;
    height: 78px;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    &--block {
      height: 100%;
      padding: 0 30px;
      position: relative;
      &:hover {
        .menu--more {
          display: block;
        }
      }
      &-active {
        border-bottom: solid 3px #fff;
      }
    }
    &--item {
      display: block;
      height: 78px;
      line-height: 84px;
      font-size: 20px;
      color: #ffffff;
      text-decoration: none;
    }
    &--more {
      display: none;
      position: absolute;
      margin: 0;
      padding: 20px 0;
      list-style: none;
      top: 78px;
      left: 0;
      right: 0;
      background: #fff;
      text-align: center;
      a {
        display: block;
        line-height: 40px;
        text-decoration: none;
        font-size: 16px;
        color: #252525;
        &:hover {
          color: #ff5033;
        }
      }
    }
  }
  .btn {
    display: none;
    float: right;
    padding: 10px;
    width: 30px;
    height: 30px;
    position: relative;
    &__item {
      display: block;
      height: 4px;
      width: 30px;
      background: #fff;
      transition: all 0.3s ease;
      &:nth-child(2) {
        margin: 8px 0;
      }
    }
    &--active {
      z-index: 99999;
      .btn__item {
        position: absolute;
        left: 50%;
        top: 50%;
        background: #fff;
        &:nth-child(1) {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
        &:nth-child(2) {
          background: transparent;
        }
        &:nth-child(3) {
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }
}
.m-top {
  position: fixed;
  z-index: 999;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  height: 46px;
  width: 46px;
  background: url("../images/top.png");
}
@media only screen and (max-width: 1000px) {
  .m-head {
    height: 50px;
    .logo {
      margin: 0 5px;
      width: 175px;
      height: 50px;
    }
    .login {
      height: 30px;
      margin: 10px;
      line-height: 30px;
      width: 90px;
      font-size: 14px;
    }
    .btn {
      display: block;
    }
    .menu {
      display: none;
      &--block {
        height: auto;
        padding: 0 20px;
        &:hover .menu--more {
          display: flex;
        }
        &-active {
          border-bottom: none;
        }
      }
      &--item {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      &--more {
        position: static;
        display: flex;
        flex-wrap: wrap;
        background: none;
        padding: 10px 0;
        li {
          width: 50%;
        }
        a {
          color: #fff;
          line-height: 30px;
          font-size: 14px;
        }
      }
      &--active {
        display: block !important;
        position: fixed;
        padding: 20px 0 0 0;
        margin: 0;
        height: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
