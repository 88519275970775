.m-footer {
  padding: 0 20px;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 25px 0 0 0;
  }
  .qr {
    float: right;
    text-align: center;
    font-size: 14px;
    color: hsl(0, 0%, 20%);
    line-height: 18px;
    margin-left: 15px;
    img {
      display: block;
      margin: 0 auto 10px auto;
    }
  }
  .content {
    display: inline-block;
    padding: 0;
    margin: 0;
    dt,
    dd {
      margin: 0;
      padding: 0;
    }
    dt {
      font-size: 18px;
      font-weight: bold;
      margin: 0 0 10px 0;
    }
    dd {
      font-size: 14px;
      color: #555555;
      line-height: 28px;
    }
  }
  .menu {
    display: inline-block;
    vertical-align: top;
    width: 600px;
    font-size: 0;
    &--block {
      display: inline-block;
      vertical-align: top;
      width: 120px;
      a {
        color: #333;
        text-decoration: none;
      }
    }
    &--item {
      font-size: 18px;
      font-weight: bold;
    }
    &--more {
      list-style: none;
      padding: 0;
      margin: 10px 0 0 0;
      font-family: Helvetica;
      font-size: 14px;
      color: #555555;
      line-height: 28px;
    }
  }
  .document {
    font-size: 0;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    li {
      border: 2px solid #ead4c1;
      display: inline-block;
      width: 22%;
      margin: 0 auto;
      font-size: 16px;
      color: #727272;
      text-align: center;
      padding-top: 20px;
      margin-top: 20px;
      img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .copyright {
    font-size: 14px;
    color: #555555;
    text-align: center;
    padding: 10px 0;
  }
}
.online {
  position: fixed;
  right: 10px;
  bottom: 50px;
  width: 48px;
  border: solid 1px #b28961;
  color: #b28961;
  text-align: center;
  font-size: 16px;
  padding: 40px 0 15px 0;
  background: #fff url(../images/online.png) center 10px no-repeat;
  cursor: pointer;
  text-decoration: none;
  &--modal {
    padding: 20px;
    border-radius: 0;
    display: none;
    .close-modal {
      top: 20px !important;
      right: 20px !important;
      background: #fff !important;
      text-indent: 0 !important;
      font-size: 0;
      position: relative;
      &:after,
      &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 1px;
        background: #000;
      }
      &:after {
        transform: rotate(45deg) translate(10px, 10px);
      }
      &:before {
        transform: rotate(-45deg) translate(-10px, 10px);
      }
    }
    h2 {
      margin: 0;
      font-size: 24px;
      line-height: 1;
      color: #c38a55;
      font-weight: normal;
      padding-bottom: 20px;
      border-bottom: solid 1px #e7e7e7;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 30px 0;
    }
    li {
      display: flex;
      align-items: center;
      margin: 20px 0;
    }
  }
  &--label {
    width: 80px;
    margin-right: 15px;
    text-align: right;
    font-size: 16px;
  }
  &--required {
    color: #c38a55;
  }
  &--input {
    width: 300px;
    line-height: 38px;
    background: #ffffff;
    border: 1px solid #c1c1c1;
    text-indent: 1em;
  }
  &--select {
    width: 306px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #c1c1c1;
  }
  &--submit {
    width: 120px;
    height: 40px;
    background: #cba581;
    border-radius: 2px;
    border: 0;
    display: block;
    margin: 20px auto;
    color: #fff;
  }
}
@media only screen and (max-width: 1000px) {
  .m-footer {
    .menu {
      display: none;
    }
    .qr {
      float: none;
      margin: 20px 0;
    }
    .content {
      text-align: center;
      display: block;
      margin-top: 20px;
    }
    .document {
      display: block;
      li {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}
