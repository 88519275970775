.banner {
  height: 500px;
  padding: 0 20px;
  background: url("../images/research-banner.png") center center / cover
    no-repeat;
  &--slogan {
    padding-top: 200px;
    strong {
      font-size: 50px;
    }
    font-size: 36px;
    color: #ffffff;
  }
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 32px;
  line-height: 1;
  color: #111;
  text-align: center;
  padding: 50px 0;
  position: relative;
  font-weight: bold;
  &:after {
    position: absolute;
    content: "";
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 3px;
    background: #d62121;
  }
}
.idea {
  &--desc {
    font-size: 24px;
    text-align: center;
    color: #c38a55;
    margin-bottom: 40px;
  }
  &--list {
    overflow: hidden;
    position: relative;
    background: #fff;
    &:after,
    &:before {
      content: "";
      position: absolute;
    }
    &:after {
      height: 10px;
      left: 0;
      right: 0;
      top: 50%;
      background: #fff;
      transform: translateY(-50%);
    }
    &:before {
      width: 10px;
      top: 0;
      bottom: 0;
      left: 50%;
      background: #fff;
      transform: translateX(-50%);
    }
    dt,
    dd {
      margin: 0;
    }
    dt {
      width: 150px;
      height: 150px;
      border: solid 20px #fff;
      border-radius: 50%;
      background: url(../images/research1.png) center center no-repeat,
        linear-gradient(135deg, rgba(255, 221, 188, 0.92) 0%, #aa8058 85%);
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    dd {
      float: left;
      width: 50%;
      background: #fff;
      padding: 0 3%;
      box-sizing: border-box;
      color: #333;
      align-items: center;
      b {
        font-size: 24px;
        display: block;
        padding-top: 30px;
      }
      p {
        font-size: 18px;
        min-height: 60px;
        margin: 0 0 30px 0;
      }
      &:nth-of-type(2n) {
        text-align: right;
      }
      &:nth-child(2) {
        background: #f1e8e0;
      }
      &:nth-child(3) {
        background: #f3eeee;
      }
      &:nth-child(4) {
        background: #f3eeee;
      }
      &:nth-child(5) {
        background: #f1e8e0;
      }
      &:hover {
        background: #caa480;
        color: #fff;
      }
    }
  }
}
.feature {
  background: url(../images/research6.png) center center / cover no-repeat;
  padding-bottom: 90px;
  &--wrap {
    overflow: hidden;
  }
  &--left {
    float: left;
    width: 600px;
    text-align: center;
  }
  &--right {
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border: 1px solid #e0d0c2;
      line-height: 100px;
      margin: 20px 0;
      padding-left: 90px;
      font-size: 20px;
      color: #333;
      &:nth-child(1) {
        background: #f1e8e0 url(../images/research3.png) 20px center no-repeat;
      }
      &:nth-child(2) {
        background: #f1e8e0 url(../images/research4.png) 20px center no-repeat;
      }
      &:nth-child(3) {
        background: #f1e8e0 url(../images/research5.png) 20px center no-repeat;
      }
    }
  }
}
.highlight {
  &--list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    color: #fff;
    li {
      width: 370px;
      padding: 40px 0;
      &:nth-child(1) {
        background: url(../images/research10.png) center center / cover
          no-repeat;
      }
      &:nth-child(2) {
        background: url(../images/research11.png) center center / cover
          no-repeat;
      }
      &:nth-child(3) {
        background: url(../images/research12.png) center center / cover
          no-repeat;
      }
    }
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
}
.login {
  padding: 20px;
  background: #383838;
  margin-top: 30px;
  overflow: hidden;
  img {
    max-width: 100%;
    float: left;
  }
  &--btn {
    float: right;
    width: 140px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #caa480;
    border-radius: 3px;
    border-radius: 3px;
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
  }
}
@media only screen and (max-width: 1000px) {
  .br {
    display: none;
  }
  .title {
    font-size: 30px;
    padding: 30px 0;
    &:after {
      bottom: 10px;
    }
  }
  .banner {
    height: 300px;
    &--slogan {
      padding-top: 100px;
      font-size: 18px;
    }
  }
  .idea {
    &--list {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
      dt {
        width: auto;
        height: auto;
        border-radius: 0;
        position: initial;
        border-width: 0;
        font-size: 16px;
        transform: none;
      }
      dd {
        b {
          font-size: 14px;
          padding-top: 10px;
        }
        p {
          font-size: 12px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  .feature {
    padding: 20px;
    &--left {
      float: none;
      width: auto;
      img {
        width: 100%;
      }
    }
    &--right {
      li {
        line-height: 1.5;
        padding-left: 90px;
        padding-right: 20px;
        height: 100px;
        display: flex;
        align-items: center;
      }
    }
  }
  .highlight {
    &--list {
      display: block;
      text-align: center;
      li {
        width: auto;
        padding: 20px 0;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .login {
    &--btn {
      width: 70px;
      height: 30px;
      line-height: 30px;
      display: block;
      float: none;
      margin: 0 auto 10px auto;
      font-size: 14px;
    }
  }
}
