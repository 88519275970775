@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_nxxkwda6a.eot"); /* IE9*/
  src: url("//at.alicdn.com/t/webfont_nxxkwda6a.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_nxxkwda6a.woff2")
      format("woff2"),
    url("//at.alicdn.com/t/webfont_nxxkwda6a.woff") format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/webfont_nxxkwda6a.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/webfont_nxxkwda6a.svg#AlibabaPuHuiTiM")
      format("svg"); /* iOS 4.1- */
}
html,
body {
  font-size: 14px;
  line-height: 1.5;
  font-family: "webfont", -apple-system, Helvetica, "Microsoft Yahei",
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
html,
body {
  scroll-behavior: smooth;
}
