.banner {
  background: url("../images/home_bg.png") center center / cover no-repeat;
  height: 100vh;
  position: relative;
  &__title {
    font-size: 56px;
    color: #ffffff;
    letter-spacing: 12.5px;
    text-align: center;
    text-shadow: 0 12px 12px rgba(115, 115, 115, 0.47);
    padding: 25vh 0 0 0;
    position: relative;
    z-index: 1;
  }
  &__slogan {
    font-size: 26px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &__video {
    cursor: pointer;
    width: 130px;
    height: 130px;
    margin: 0 auto;
    background: url("../images/video.png") center center / cover no-repeat;
    position: relative;
    z-index: 1;
  }
  &__mouse {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 90px;
    margin: 0 auto;
    background: url("../images/roll.png") center center / cover no-repeat;
  }
  #background_video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  &:target {
    padding-top: 100px;
  }
}
.title {
  font-size: 32px;
  line-height: 1;
  color: #111;
  text-align: center;
  padding: 50px 0;
  position: relative;
  font-weight: bold;
  &:after {
    position: absolute;
    content: "";
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 3px;
    background: #d62121;
  }
}
.who {
  background: url("../images/about1.jpg") center center/ cover no-repeat;
  color: #111;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
  &__content {
    opacity: 0.71;
    background: #ffffff;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.09);
    padding: 20px;
    margin-bottom: 110px;
    overflow: hidden;
  }
  &__right {
    background-image: linear-gradient(0deg, #dcb794 18%, #aa8058 100%);
    padding: 0 30px;
    width: 240px;
    float: right;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
  &__title {
    font-size: 26px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    justify-content: center;
  }
  &__sub {
    font-size: 20px;
    line-height: 30px;
    border-top: dotted 1px #fff;
    padding: 10px 0;
  }
  &__text {
    padding: 0 70px;
    font-size: 18px;
    line-height: 40px;
    text-indent: 2em;
    overflow: hidden;
    text-align: left;
  }
}
.model {
  padding: 0 20px;
  padding-bottom: 50px;
  &__text {
    text-align: center;
    font-size: 32px;
    color: #c38a55;
    padding-bottom: 30px;
    font-weight: bold;
  }
  &__block {
    overflow: hidden;
    text-align: center;
    &-img {
      text-align: center;
      background: #fff;
      padding: 30px 20px;
      img {
        max-width: 100%;
      }
    }
    &-left {
      float: left;
      width: 520px;
      background: #c9c8c8;
      border: solid 3px #c9c8c8;
      color: #c9c8c8;
      position: relative;
    }
    &-right {
      float: right;
      width: 650px;
      background: #cda784;
      border: solid 3px #cda784;
      color: #cda784;
      position: relative;
    }
    &-title {
      position: absolute;
      left: 25px;
      top: 15px;
      font-size: 30px;
    }
    &-list {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 30px 0;
      color: #fff;
      font-size: 24px;
      line-height: 2;
      text-align: left;
      li {
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          background: #fff;
          display: inline-block;
          margin: 0 20px 0 0;
        }
      }
    }
  }
  &__line {
    height: 1px;
    background: #dedede;
    margin: 50px 0 20px 0;
  }
  &__chart {
    text-align: center;
    position: relative;
    max-width: 1460px;
    margin: 0 auto;
    font-size: 38px;
    img {
      max-width: 100%;
    }
    div {
      position: absolute;
      color: #c38a55;
      width: 180px;
      margin-left: -90px;
      top: 38.3%;
    }
    strong {
      font-size: 20px;
    }
    p {
      margin: 0;
      color: #333;
      font-size: 20px;
    }
    &-number1 {
      left: 22.5%;
    }
    &-number2 {
      left: 39%;
    }
    &-number3 {
      left: 60.5%;
    }
    &-number4 {
      left: 77%;
    }
    &-number5 {
      top: 61% !important;
      left: 50%;
    }
  }
}

.core {
  background: url("../images/bg3.png") right top no-repeat,
    url("../images/bg3.png") left bottom no-repeat;
  background-color: #f0f0f0;
  padding: 0 20px;
  &__desc {
    font-size: 16px;
    color: #333333;
    text-align: center;
    line-height: 30px;
  }
  &__list {
    position: relative;
    font-size: 0;
    padding-bottom: 200px;
  }
  &__item {
    width: 25%;
    display: inline-block;
    text-align: center;
    padding: 30px 0;
    cursor: pointer;
    &--active {
      border-bottom: solid 4px #c39c78;

      .core__item_img1 {
        display: none;
      }
      .core__item_img2 {
        display: inline;
      }
      .core__item_content {
        display: block;
      }
    }
    &_img1 {
      display: inline;
    }
    &_img2 {
      display: none;
    }
    &_block {
      h2 {
        font-size: 24px;
        line-height: 1;
        margin: 20px 0;
        color: #c38a55;
      }
      p {
        font-size: 16px;
        line-height: 1;
        margin: 0;
      }
    }
    &_content {
      display: none;
      text-indent: 2em;
      position: absolute;
      top: 266px;
      left: 0;
      right: 0;
      font-size: 18px;
      background: #f1e8e0;
      padding: 20px 30px;
      text-align: left;
      border-top: solid 1px #dedede;
    }
  }
  &__subtitle {
    font-size: 36px;
    line-height: 1;
    color: #c38a55;
    text-align: center;
  }
  &__subdesc {
    font-size: 20px;
    color: #333333;
    text-align: center;
    margin: 20px 0;
  }
  &__link {
    font-size: 0;
    padding: 0 0 70px 0;
    margin: 0 -2px;
    li {
      display: inline-block;
      width: 164px;
      margin: 0 4px;
      padding-bottom: 8px;
    }
  }
}
.incident {
  padding: 0 20px;
  &--nav {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-item {
      position: relative;
      display: inline-block;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      line-height: 54px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      font-size: 16px;
      color: #8c8c8c;
      cursor: pointer;
      opacity: 1;
      &:active,
      &.swiper-pagination-bullet-active {
        outline: none;
        background: #cda784;
        color: #fff;
        border-color: #cda784;
        &:before {
          display: block;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 50%;
        height: 6px;
        width: 1px;
        background: #cbcaca;
      }
      &:before {
        display: none;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -24px;
        margin-left: -3px;
        width: 6px;
        height: 6px;
        background: url("../images/jt2.png") center center / cover no-repeat;
      }
    }
  }
  &--line {
    height: 1px;
    background: #cbcaca;
    margin: 10px -20px 40px -20px;
  }
  &--list {
    &-time {
      background: #c09974;
      position: absolute;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 14px;
      color: #fff;
      padding: 5px;
      b {
        display: block;
        font-size: 24px;
        border-bottom: 1px solid #fff;
      }
    }
    &-item {
      position: relative;
      display: block;
      margin-left: 30px;
      padding: 20px 30px 20px 60px;
      background: #f8f8f8;
      color: #333333;
      text-decoration: none;
      margin-bottom: 10px;
      &:hover {
        h2 {
          color: #cda784;
          text-decoration: underline;
        }
      }
      h2 {
        font-size: 18px;
        padding: 10px 0;
        margin: 0;
      }
      p {
        font-size: 14px;
        margin: 0;
      }
    }
    .more {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  &--wrap {
    padding: 25px;
    background: #f5f5f5;
    overflow: hidden;
  }
  &--right {
    float: right;
    width: 330px;
    height: 330px;
    padding: 5px;
    background-image: linear-gradient(to bottom, #d7bb97, #ae855e);
    .swiper-pagination {
      bottom: 90px;
      left: auto;
      width: auto;
      right: 10px;
      span {
        border-radius: 0;
        width: 8px;
        height: 8px;
        border: 1px solid #b48d66;
        background: #fff;
        opacity: 1;
      }
    }
    .swiper-pagination-bullet-active {
      background: #b48d66 !important;
    }
  }
  &--left {
    overflow: hidden;
    h2 {
      font-size: 24px;
      line-height: 1;
      color: #a1a1a1;
      margin: 0;
    }
    ul {
      font-size: 16px;
      color: #333;
      padding: 0;
      list-style: none;
      padding: 0 20px 0 50px;
    }
    li {
      margin: 20px 0;
    }
    span {
      position: absolute;
      width: 40px;
      border-radius: 2px;
      line-height: 24px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      margin-left: -50px;
    }
    a {
      font-size: 16px;
      color: #333;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &--honor {
    background-image: linear-gradient(
      -32deg,
      rgba(255, 221, 188, 0.92) 0%,
      #aa8058 87%
    );
  }
  &--feature {
    background-image: linear-gradient(
      -54deg,
      rgba(155, 155, 155, 0.92) 0%,
      #494949 65%
    );
  }
}

@media only screen and (max-width: 1240px) {
  .model {
    &__block {
      &-left,
      &-right {
        width: auto;
        float: none;
        margin: 20px 0;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .br {
    display: none;
  }
  .banner {
    &__title {
      font-size: 50px;
    }
    &__slogan {
      font-size: 24px;
    }
  }
  .title {
    font-size: 30px;
    padding: 30px 0;
    &:after {
      bottom: 10px;
    }
  }
  .who {
    &__content {
      margin-bottom: 20px;
    }
    &__right {
      width: auto;
      float: none;
    }
    &__text {
      padding: 20px 0 0 0;
    }
  }
  .model {
    &__text {
      font-size: 18px;
      padding-bottom: 20px;
    }
    &__line {
      margin: 20px 0 10px 0;
    }
    &__block {
      &-left,
      &-right {
        border-width: 2px;
      }
      &-title {
        font-size: 16px;
        left: 10px;
        top: 10px;
      }
      &-img {
        padding: 10px;
      }
      &-list {
        font-size: 14px;
        padding: 10px 0;
        li {
          &:before {
            width: 6px;
            height: 6px;
          }
        }
      }
    }
    &__chart {
      & > div {
        transform: scale(0.4) translateY(-70%);
      }
    }
  }

  .core {
    &__desc {
      font-size: 14px;
      line-height: 20px;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;
    }
    &__list {
      position: relative;
      font-size: 0;
      padding-bottom: 0;
    }
    &__item {
      width: auto;
      display: block;
      border-bottom: 0 !important;
      padding: 0 0 30px 0;
      &_img1 {
        display: inline !important;
      }
      &_img2 {
        display: none !important;
      }
      &_content {
        display: block !important;
        margin-top: 30px;
        position: static;
        border-top: solid 1px #dedede;
      }
    }
    &__link {
      padding: 0 0 20px 0;
      margin: 0 -2px;
      li {
        width: 50%;
        margin: 0;
        text-align: center;
        padding-bottom: 8px;
        img {
          width: 90%;
        }
      }
    }
  }
  .incident {
    padding: 0;
    &--wrap {
      padding: 20px;
    }
    &--line {
      margin-right: 0;
      margin-left: 0;
    }
    &--right {
      float: none;
      width: auto;
      height: auto;
      img {
        width: 100%;
      }
    }
    &--left {
      margin: 20px 0;
    }
  }
}
@media only screen and (max-width: 600px) {
  .model__chart {
    & > div {
      transform: scale(0.2) translateY(-220%);
    }
  }
}
