.banner {
  height: 500px;
  padding: 0 20px;
  background: url("../images/bg7.png") center center / cover no-repeat;
  &--slogan {
    img {
      display: block;
      padding-top: 220px;
      padding-bottom: 30px;
    }
    font-size: 36px;
    color: #ffffff;
  }
}
.inner {
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 32px;
  line-height: 1;
  color: #111;
  text-align: center;
  padding: 50px 0;
  position: relative;
  font-weight: bold;
  &:after {
    position: absolute;
    content: "";
    bottom: 25px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 3px;
    background: #d62121;
  }
}
.info {
  background: #f0f0f0;
  padding: 0 20px;
  &--logo {
    text-align: center;
    img {
      max-width: 100%;
      padding-top: 5%;
    }
  }
  &--text {
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 1.5;
    margin: 2% 0;
  }
  &--img {
    position: relative;
    img {
      display: block;
    }
    ul {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }
    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      font-size: 20px;
    }
    h3 {
      margin: 0;
    }
    span {
      font-size: 40px;
    }
    p {
      margin: 0;
    }
  }
  &--slogan {
    // display: flex;
    text-align: center;
    // align-items: center;
    // justify-content: center;
    color: #ad8e76;
    font-size: 24px;
    margin: 20px 0 0 0;
    strong {
      font-size: 44px;
      margin-top: -10px;
      color: #957964;
    }
    img {
      vertical-align: top;
    }
  }
  &--desc {
    text-align: center;
    color: #acacac;
    font-size: 14px;
    padding-bottom: 40px;
  }
  // &--title {
  //   font-size: 36px;
  //   color: #c38a55;
  //   text-align: center;
  //   margin: 2% 0;
  // }
  // &--img {
  //   text-align: center;
  //   img {
  //     max-width: 100%;
  //   }
  // }
  // &--list {
  //   display: flex;
  //   padding: 0 0 4% 0;
  //   margin: 0;
  //   list-style: none;
  //   li {
  //     background: #ffffff;
  //     border: 2px solid #e0d0c2;
  //     width: 48%;
  //     font-size: 18px;
  //     text-align: center;
  //     color: #c38a55;
  //     padding: 2% 0;
  //     &:first-child {
  //       margin: 0 auto 0 0;
  //     }
  //     &:last-child {
  //       margin: 0 0 0 auto;
  //     }
  //   }
  //   img {
  //     display: block;
  //     margin: 0 auto;
  //     padding-bottom: 1%;
  //   }
  // }
}
.system {
  &--block {
    background-image: linear-gradient(
      135deg,
      rgba(255, 221, 188, 0.92) 0%,
      #aa8058 85%
    );
    overflow: hidden;
    &-left {
      float: left;
      width: 32%;
      padding-bottom: 9999px;
      margin-bottom: -9999px;
      background: #a08370;
    }
    &-right {
      float: left;
      width: 68%;
      font-size: 14px;
      position: relative;
    }
    &-name {
      position: absolute;
      width: 500px;
      left: -550px;
      top: 50%;
      transform: translateY(-50%);
      text-align: right;
      font-size: 38px;
      color: #ffffff;
      line-height: 60px;
    }
    &-item {
      width: 260px;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      padding: 40px 0;
      position: relative;
      &:after {
        content: "+";
        position: absolute;
        right: -20px;
        top: 60px;
        font-size: 68px;
        color: #e0c4ab;
      }
      &:last-child:after {
        display: none;
      }
      img {
        display: block;
        margin: 0 auto;
      }
      ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        width: 150px;
        display: inline-block;
        border: 3px solid #e0c4ab;
        font-size: 18px;
        line-height: 2;
        color: #fff;
      }
    }
    &-head {
      font-size: 24px;
      color: #ffffff;
      padding: 0 0 20px 0;
    }
  }
}
.function {
  &--title {
    font-size: 34px;
    line-height: 1;
    color: #c38a55;
    text-align: center;
    margin: 40px 0;
  }
  &--desc {
    font-size: 14px;
    color: #333;
    text-align: center;
    margin: 40px 0;
  }
  &--list {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 400px;
  }
  &--item {
    width: 370px;
  }
  &--block {
    position: relative;
    border: solid 1px #c38a55;
    text-align: center;
    height: 280px;
    cursor: pointer;
    h2 {
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      img {
        &:last-child {
          display: none;
        }
      }
    }
    h3 {
      font-size: 24px;
      line-height: 1;
      margin: 0 0 20px 0;
      color: #c38a55;
    }
    p {
      font-size: 18px;
      margin: 0;
    }
  }
  &--hover {
    position: absolute;
    top: 400px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    display: none;
    dl {
      width: 270px;
      margin: 0 50px;
      padding: 10px 0;
      position: relative;
      border-radius: 4px;
      &:nth-child(1) {
        background-image: linear-gradient(180deg, #f8f6f5 0%, #edebe8 100%);
        dt {
          background-image: linear-gradient(180deg, #f8f6f5 0%, #edebe8 100%);
        }
        dd {
          color: #666;
          background: url(../images/about7.png) 40px 4px no-repeat;
        }
      }
      &:nth-child(2) {
        background-image: linear-gradient(180deg, #f4ede6 0%, #e6d6c8 100%);
        dt {
          background-image: linear-gradient(180deg, #f4ede6 0%, #e6d6c8 100%);
        }
        dd {
          color: #333;
          background: url(../images/about8.png) 40px 4px no-repeat;
        }
      }
      &:nth-child(3) {
        background-image: linear-gradient(180deg, #efe0d0 0%, #dabea3 100%);
        dt {
          background-image: linear-gradient(180deg, #efe0d0 0%, #dabea3 100%);
        }
        dd {
          color: #333;
          background: url(../images/about9.png) 40px 4px no-repeat;
        }
      }
    }
    dt {
      position: absolute;
      left: 50%;
      top: -50px;
      transform: translateX(-50%);
      width: 170px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      font-size: 20px;
      color: #6b4d2f;
    }
    dd {
      margin: 20px 0;
      padding-left: 76px;
      padding-right: 20px;
      font-size: 18px;
    }
  }
  &--active {
    .function--block {
      background: #f1e8e0;
      h2 img {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
      &:after,
      &:before {
        position: absolute;
        left: 50%;
        margin-left: -15px;
        bottom: -30px;
        content: "";
        height: 0;
        width: 0;
        border: solid 15px transparent;
      }
      &:after {
        bottom: -29px;
        border-top-color: #f1e8e0;
      }
      &:before {
        border-top-color: #c38a55;
      }
    }
    .function--hover {
      display: flex;
    }
  }

  // &--list {
  //   list-style: none;
  //   padding: 0 0 20px 0;
  //   margin: 0;
  //   text-align: center;
  //   font-size: 0;
  //   li {
  //     width: 20%;
  //     display: inline-block;
  //     vertical-align: top;
  //   }
  //   b {
  //     display: block;
  //     font-size: 24px;
  //     color: #c38a55;
  //     padding-top: 8%;
  //   }
  //   strong {
  //     display: block;
  //     font-size: 20px;
  //     color: #333333;
  //     padding: 0 10px;
  //   }
  //   p {
  //     display: block;
  //     font-size: 16px;
  //     line-height: 1.5;
  //     color: #333333;
  //     padding: 0 10px;
  //   }
  // }
}
.study {
  background: url("../images/bg1.png") center center/ cover no-repeat;
  padding: 0 20px 70px 20px;
  &--text {
    font-size: 36px;
    color: #c38a55;
    text-align: center;
  }
  &--text2 {
    font-size: 20px;
    color: #333333;
    text-align: center;
    line-height: 2;
  }
  &--list {
    overflow: hidden;
    position: relative;
    background: #fff;
    &:after,
    &:before {
      content: "";
      position: absolute;
    }
    &:after {
      height: 10px;
      left: 0;
      right: 0;
      top: 50%;
      background: #f0f0f0;
      transform: translateY(-50%);
    }
    &:before {
      width: 10px;
      top: 0;
      bottom: 0;
      left: 50%;
      background: #f0f0f0;
      transform: translateX(-50%);
    }
    dt,
    dd {
      margin: 0;
    }
    dt {
      width: 150px;
      height: 150px;
      border: solid 20px #f0f0f0;
      border-radius: 50%;
      background-image: linear-gradient(
        135deg,
        rgba(255, 221, 188, 0.92) 0%,
        #aa8058 85%
      );
      font-size: 34px;
      color: #ffffff;
      text-align: center;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    dd {
      float: left;
      width: 50%;
      background: #fff;
      padding: 0 3%;
      box-sizing: border-box;
      color: #333;
      align-items: center;
      b {
        font-size: 24px;
        display: block;
        padding-top: 30px;
      }
      p {
        font-size: 18px;
        min-height: 60px;
        margin: 0 0 30px 0;
      }
      &:nth-of-type(2n) {
        text-align: right;
      }
      &:hover {
        background: #caa480;
        color: #fff;
      }
    }
  }
}
.exclusive {
  padding: 0 20px;
  background: #f0f0f0;
  &--text {
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 1.5;
    padding: 0 20px;
  }
  &--list {
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
    p {
      opacity: 0;
      font-size: 14px;
      line-height: 1.5;
      color: #ffffff;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #c39c77;
      margin: 0;
      padding: 20px;
      display: flex;
      align-items: center;
      text-align: left;
      transition: opacity 0.3s ease;
    }
    li {
      width: 49%;
      text-align: center;
      font-size: 30px;
      line-height: 1.5;
      color: #ffffff;
      text-align: center;
      padding: 20px 0;
      position: relative;
      margin: 10px 0;
      img {
        display: block;
        margin: 0 auto;
        padding: 20px 0;
      }
      &:hover p {
        opacity: 1;
      }
      &:nth-child(1n) {
        float: left;
      }
      &:nth-child(2n) {
        float: right;
      }
      &:nth-child(1) {
        background: url("../images/pic1.png") center center / cover no-repeat;
      }
      &:nth-child(2) {
        background: url("../images/pic2.png") center center / cover no-repeat;
      }
      &:nth-child(3) {
        background: url("../images/pic3.png") center center / cover no-repeat;
      }
      &:nth-child(4) {
        background: url("../images/pic4.png") center center / cover no-repeat;
      }
    }
  }
}
.login {
  padding: 20px;
  background: #383838;
  margin-top: 30px;
  overflow: hidden;
  img {
    max-width: 100%;
    float: left;
  }
  &--btn {
    float: right;
    width: 140px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background: #caa480;
    border-radius: 3px;
    border-radius: 3px;
    font-size: 24px;
    color: #ffffff;
    text-decoration: none;
  }
}
@media only screen and (max-width: 1000px) {
  .br {
    display: none;
  }
  .title {
    font-size: 30px;
    padding: 30px 0;
    &:after {
      bottom: 10px;
    }
  }
  .banner {
    height: 300px;
    &--slogan {
      img {
        padding: 110px 0 20px 0;
      }
      font-size: 18px;
    }
  }
  .info {
    &--text {
      font-size: 14px;
      text-align: left;
    }
    &--title {
      font-size: 18px;
    }
    &--list {
      li {
        font-size: 12px;
      }
    }
  }
  .system {
    &--text {
      font-size: 18px;
      margin-bottom: 20px;
    }
    &--block {
      &-left {
        display: none;
      }
      &-right {
        float: none;
        width: auto;
        text-align: center;
      }
      &-name {
        position: initial;
        width: 100%;
        text-align: center;
        transform: none;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        padding-top: 20px;
        b {
          font-size: 20px;
        }
      }
      &-item {
        width: 100%;
        padding: 20px 0;
        &:after {
          display: none;
        }
        img {
          display: block;
          margin: 0 auto;
        }
        ul {
          border: 3px solid #e0c4ab;
          font-size: 14px;
        }
      }
      &-head {
        font-size: 16px;
        padding: 0 0 10px 0;
      }
    }
  }
  .function {
    padding: 0 20px;
    &--title {
      font-size: 18px;
    }
    &--desc {
      text-align: left;
    }
    // &--list {
    //   padding: 0 0 10px 0;
    //   li {
    //     width: 50%;
    //   }
    //   b {
    //     font-size: 14px;
    //   }
    //   strong {
    //     font-size: 14px;
    //   }
    //   p {
    //     font-size: 12px;
    //   }
    // }
    &--list {
      display: block;
      padding: 0;
    }
    &--item {
      width: auto;
    }
    &--block {
      position: relative;
      background: #f1e8e0;
      h2 img {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
      &:after,
      &:before {
        position: absolute;
        left: 50%;
        margin-left: -15px;
        bottom: -30px;
        content: "";
        height: 0;
        width: 0;
        border: solid 15px transparent;
      }
      &:after {
        bottom: -29px;
        border-top-color: #f1e8e0;
      }
      &:before {
        border-top-color: #c38a55;
      }
    }
    &--hover {
      position: static;
      display: block !important;
      justify-content: space-between;
      margin-bottom: 20px;
      dl {
        margin: 70px 0 0 0;
        width: auto;
      }
    }
  }
  .study {
    padding-bottom: 30px;
    &--text {
      font-size: 18px;
    }
    &--text2 {
      font-size: 12px;
    }
    &--list {
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
      dt {
        width: auto;
        height: auto;
        border-radius: 0;
        position: initial;
        border-width: 0;
        font-size: 16px;
        transform: none;
      }
      dd {
        b {
          font-size: 14px;
          padding-top: 10px;
        }
        p {
          font-size: 12px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  .exclusive {
    &--text {
      font-size: 14px;
    }
    &--list {
      p {
        position: relative;
        opacity: 0.7;
        font-size: 14px;
        margin-top: 20px;
      }
      li {
        width: 100%;
        font-size: 14px;
        padding: 0;
        img {
          padding: 10px 0;
        }
      }
    }
  }
  .login {
    &--btn {
      width: 70px;
      height: 30px;
      line-height: 30px;
      display: block;
      float: none;
      margin: 0 auto 10px auto;
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .info {
    &--img {
      span {
        font-size: 16px;
      }
      li {
        font-size: 12px;
      }
    }
  }
}
