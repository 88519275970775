@import "./_base/normalize.scss";
@import "../fonts/font-awesome-4.7.0/scss/font-awesome.scss";
@import "./_base/config.scss";

@import "./m-head.scss";
@import "./m-footer.scss";
@import "./m-nav.scss";
// 区分模块
.p-index {
  @import "./index.scss";
}
.p-about {
  @import "./about.scss";
}
.p-research {
  @import "./research.scss";
}
.p-news {
  @import "./news.scss";
}
.p-contact {
  @import "./contact.scss";
}
.point {
  position: absolute;
  height: 0;
  width: 0;
  margin-top: -78px;
}
@media only screen and (max-width: 1000px) {
  .point {
    margin-top: -50px;
  }
}
.inner {
  transform: translateY(50px);
  opacity: 0;
  transition: all 1s ease;
  &.animate-enter{
    transform: translateY(0);
    opacity: 1;
  }
}